import { object, SchemaOf } from "yup";
import { arrayOptional, enumRequired, statusSchema, stringOptional, stringRequired } from "../../moo-schemas";
import { Customer, Gender } from "../@types/customer";

// @ts-ignore
export const schema: SchemaOf<Customer> = object().shape({
	create_date: stringRequired("create_date"),
	update_date: stringRequired("update_date"),
	status: statusSchema,
	type_id: stringRequired("type_id"),
	name: stringRequired("name"),
	gender: enumRequired(Gender),
	birthday: stringOptional,
	email: stringOptional,
	tels: arrayOptional,
	line: stringOptional,
	job: stringOptional,
	vip: stringOptional,
	address: stringRequired("address"),
	bank_account: stringOptional,
	bank: stringOptional,
	bank_account_number: stringOptional,
	tax_id: stringOptional,
	note: stringOptional,
});
