import { array, boolean, date, mixed, number, SchemaOf, string as yupString } from "yup";
import { Status } from "../moo-enums";

export const stringOptional = yupString().nullable(true);
export const booleanOptional = boolean().nullable(true);
export const numberOptional = number().nullable(true);

export const arrayOptional = array();

export const numberRequired = (name: string, warning = "請提供") => {
	return numberAllowZeroRequired(name, warning, false);
};

export const numberAllowZeroRequired = (name: string, warning = "請提供", allowZero: boolean) => {
	return number()
		.nullable(true)
		.test(name, warning, (value) => {
			if (value === null) {
				return false;
			} else if (!allowZero && value === 0) {
				return false;
			}
			return true;
		});
};

export const stringRequired = (name: string, warning = "請提供") => {
	return yupString()
		.nullable(true)
		.test(name, warning, (value) => {
			return value !== null && value !== undefined && value !== "";
		});
};

export const stringArray = (name: string, warning = "請提供") => {
	return array().of(stringRequired(name, warning));
};
export const dateRequired = (name: string, warning = "請提供") => {
	return date()
		.nullable(true)
		.test(name, warning, (value) => {
			return value !== null;
		})
		.typeError("此日期格式不正確");
};

export const items = array().test("items", "請至少提供一項", (value: any) => {
	return value.length >= 1;
}) as any;

export function enumRequired(value: string | any) {
	return mixed<typeof value>().oneOf(Object.values(value)).required("請提供");
}

export const statusSchema = enumRequired(Status);

export const arrayRequiredSchema = (schema: SchemaOf<any>, name: string, warning: string) => {
	return array(schema).test(name, warning, (items: any) => {
		return items.length >= 1;
	}) as any;
};

export const arrayOptionalSchema = (schema: SchemaOf<any>) => {
	return array(schema) as any;
};
